<template>
  <div class="systemLog"
    v-loading.fullscreen.lock="loadingList"
    element-loading-text="加载中...">
    <div class="simpleInfoPanel" v-if="hasPermission">
      <ul class="filtroPanel">
        <li>
          <span>操作人：</span>
          <el-input
            placeholder="输入姓名/用户名查询"
            v-model="form.operator"
            clearable
            ></el-input>
        </li>
        <li>
          <el-button class="btnPrimary btnCommon" @click="onSearch">查询</el-button>
        </li>
      </ul>
    </div>
    <div class="simpleInfoPanel mt15" v-if="hasPermission">
      <el-table
        :data="tableData"
        class="tbsWrap"
        highlight-current-row>
        <el-table-column
          prop="order"
          width="80"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="operator"
          width="130"
          label="操作人">
        </el-table-column>
        <el-table-column
          prop="date"
          width="230"
          label="操作时间">
        </el-table-column>
        <el-table-column
          prop="ipAdress"
          width="200"
          label="ip地址">
        </el-table-column>
        <el-table-column
          prop="elapsedTime"
          width="130"
          label="操作耗时">
        </el-table-column>
        <el-table-column
          prop="remarks"
          label="操作描述">
        </el-table-column>
      </el-table>
      <div class="w-page-sty">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="params.total"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          @current-change="handlePages"
          >
        </el-pagination>
        <br>
      </div>
    </div>
    <div class="simpleInfoPanel" v-else>
      <div class="jurisdiction-wrap">
        <img src="~@/assets/images/Jurisdiction.png" alt="">
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'systemLog',
  components: {},
  data () {
    return {
      form: {
        operator: ''
      },
      tableData: [],
      params: {
        name: '',
        pageNum: 1,
        total: 0,
        pageSize: 20
      },
      userAuthorities: [],
      hasPermission: true,
      loadingList: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 81
    })
    if (this.userAuthorities.length) {
      this.hasPermission = true
      this.getDataLogList()
    } else {
      this.hasPermission = false
    }
  },
  methods: {
    onSearch () {
      this.params.name = this.form.operator
      this.params.pageNum = 1
      this.getDataLogList()
    },
    getDataLogList () {
      this.loadingList = true
      this.axios.post(api.systemLogList, this.params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData) {
            this.tableData = resData.dataList.map((item, index) => {
              const tempObj = {
                order: index + 1,
                operator: item.userName,
                date: item.createTime,
                ipAdress: item.ip,
                elapsedTime: item.time,
                remarks: item.description
              }
              return tempObj
            })
            this.params.total = resData.total
          }
          console.log(resData)
        }
        this.loadingList = false
      }, rej => {
        console.log(rej)
        this.loadingList = false
      }).catch(err => {
        console.log(err)
        this.loadingList = false
      })
    },
    handlePages (val) {
      this.params.pageNum = val
      this.getDataLogList()
    }
  }
}
</script>

<style scoped lang="stylus">

</style>
